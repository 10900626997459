/* Config */
var phoneBreakpoint = 500;
var tabletBreakpoint = 768;

var isMobile,
    isTablet,
    isPhone,
    isTouch;

$(document).ready(function () {
    deviceCheck();
    changeEvents();
    customScrollbars();
    responsiveVideoEmbed();
    IEcheck();
    tabbedContentBlock();
    //imageFill();
    stopInputFocusZoom();
    accessibility();
    sectionToggle();
    radDropDownMaxWidth();
    collapseState
    formUX();
    accordion();
    sectionMenuTabs();
    var primaryAcc = dashboardAccordions()
    if (document.getElementsByClassName("c-accordion").length > 0) accordionActivateItem(primaryAcc);
    if (document.getElementsByClassName("side-menu-items--name").length > 0) collapseState();
    if (document.getElementsByClassName("c-summary-list").length > 0) buttonPositioning();
    if (document.getElementsByClassName("c-general-accordion").length > 0) pageAccordions();
    if (document.getElementsByClassName("c-file-upload").length > 0) uploader.init();
    if (document.getElementsByClassName("carousel").length > 0) carousel();
});


function buttonPositioning() {
    //var wholeElement = $(".content-block-dashboard").height();
    //var header = $(".content-block-dashboard-account").children().first().outerHeight();
    //var licenceBlock = $(".content-block-dashboard-account").find(".entity-detail").height();
    //var buttonContainer = $(".button-container").outerHeight();
    //var otherElements = $(".summary-block").outerHeight();
    //if (licenceBlock == undefined) {
    //    licenceBlock = 0 - 40;
    //}

    //var margin = wholeElement - (buttonContainer + otherElements + header + licenceBlock + 40);

    //if (margin < 0) {
    //    margin = 0;
    //}


    //$(".button-container").css("margin-top", margin + "px");
    //$(".button-container").css("padding-bottom", "0px");
    //$(".button-container").css("padding-top", "0px");


};


// Custom browser upload feature that hides features upon button clicks, and changes the browser default input file type to use a custom build input workaround
var uploader = {};

uploader.bind = function () {
    uploader.initFakeInput();
    uploader.cancelBtn.off("click").on("click", function () {
        uploader.removeFile($(this));
        uploader.hideFileInput($(this));
        uploader.showFileDetails($(this));
    });
    uploader.replaceBtn.off("click").on("click", function () {
        uploader.hideFileDetails($(this));
        uploader.showFileInput($(this));
    });
};

uploader.initFakeInput = function () {
    for (var i = 0; i < uploader.container.length; i++) {
        var container = $(uploader.container[i]);
        var imageUpload = container.find(uploader.inputTarget);
        imageUpload.on("change", function () {
            var uploadMsg = $(this).parents("." + uploader.containerClass).find("." + uploader.fileUploadMsgClass),
                input = this.files[0],
                text,
                val = $(this).val();

            if (input) {
                text = 'Selected File to upload: <strong>' + val.replace(/.*\\(.+)/g, "$1") + "</strong>";
            } else {
                text = 'Please select a file';
            }

            uploadMsg.html(text);
        });
    }
};

uploader.removeFile = function (selected) {
    var container = selected.parents("." + uploader.containerClass);
    var input = container.find(uploader.inputTarget);
    var uploadMsg = container.find("." + uploader.fileUploadMsgClass);
    input.val("");
    uploadMsg.html("No File Selected");
};

uploader.hideFileInput = function (selected) {
    var container = selected.parents("." + uploader.containerClass);
    var fileOpts = container.find("." + uploader.fileOptsClass);
    fileOpts.addClass(uploader.fileOptsHiddenClass);
};

uploader.showFileInput = function (selected) {
    var container = selected.parents("." + uploader.containerClass);
    var fileOpts = container.find("." + uploader.fileOptsClass);
    fileOpts.removeClass(uploader.fileOptsHiddenClass);
};

uploader.hideFileDetails = function (selected) {
    var container = selected.parents("." + uploader.containerClass);
    var fileDetails = container.find("." + uploader.fileDetailsClass);
    fileDetails.addClass(uploader.fileDetailsHiddenClass);
};

uploader.showFileDetails = function (selected) {
    var container = selected.parents("." + uploader.containerClass);
    var fileDetails = container.find("." + uploader.fileDetailsClass);
    fileDetails.removeClass(uploader.fileDetailsHiddenClass);
};

uploader.init = function () {
    uploader.containerClass = "c-file-upload";
    uploader.cancelBtnClass = "c-file-upload__cancel";
    uploader.replaceBtnClass = "c-file-upload__replace";
    uploader.fileOptsClass = "c-file-upload__file-opts";
    uploader.fileOptsHiddenClass = "c-file-upload__file-opts--hidden";
    uploader.fileDetailsClass = "c-file-upload__file-details";
    uploader.fileDetailsHiddenClass = "c-file-upload__file-details--hidden";
    uploader.fileUploadMsgClass = "c-file-upload__upload-msg";
    uploader.inputTarget = "input[type=file]";
    uploader.container = $("." + uploader.containerClass);
    uploader.fileInput = uploader.container.find(uploader.inputTarget);

    if (uploader.fileInput.length > 0) {
        uploader.cancelBtn = uploader.container.find("." + uploader.cancelBtnClass);
        uploader.replaceBtn = uploader.container.find("." + uploader.replaceBtnClass);
        uploader.bind();
    }
};

var pageAccordions = function () {

    var generalAccordion = new logicjs.accordions({
        container: ".c-general-accordion",
        accordionsClass: ".c-general-accordion__item",
        accordionCtrlTarget: ".c-general-accordion__toggle-ctrl",
        hiddenClass: "hidden",
        accordionView: ".c-general-accordion__view",
        loadActiveClass: "initially-opened",
        toggleAccordions: false,
        disableAccordionClick: false,
        loadingClass: "loading",
        scrollToView: false,
        scrollUnderClassList: ["outer-wrapper-preheader", "outer-wrapper-header"],
        scrollTransitionDelay: 220,
        urlHashLoad: true,
        urlHashAttributeSelector: "data-id"
    });
};

var dashboardAccordions = function () {

    var primaryAccordions = new logicjs.accordions({
        container: ".c-accordion",
        accordionsClass: ".c-accordion__item",
        accordionCtrlTarget: ".c-accordion__toggle-ctrl",
        hiddenClass: "hidden",
        accordionView: ".c-accordion__view",
        loadActiveClass: "c-accordion--open",
        toggleAccordions: true,
        loadingClass: "loading"
    });
    var subAccordions = new logicjs.accordions({
        container: ".c-sub-accordion",
        accordionsClass: ".c-sub-accordion__item",
        accordionCtrlTarget: ".c-sub-accordion__toggle-ctrl",
        hiddenClass: "hidden",
        accordionView: ".c-sub-accordion__view",
        loadActiveClass: "c-sub-accordion--open",
        loadingClass: "loading",
        toggleAccordions: false,
        //scrollToView: true,
        //scrollUnderClassList: ["c-site-header", "c-hero-banner__sub-nav"],
        //scrollTransitionDelay: 220,
        parentAccordionView: primaryAccordions.accordionView
    });

    return primaryAccordions

};


var accordion = function () {

    $('.accordion').each(function () {
        $(this).addClass('enabled');
        $(this).find('.accordion-title, .accordion-content').addClass('inactive');
        var hasActiveSection = $(this).find('.accordion-title.active').length > 0;
        if (hasActiveSection) {
            $(this).find('.accordion-title.active').next('.accordion-content').addClass('active').removeClass('inactive');
        }
        else {
            //$(this).find('.accordion-title:first-child').addClass('active').removeClass('inactive').next('.accordion-content').addClass('active').removeClass('inactive');
        }
    });

    $('.accordion-title').click(function () {
        var isActive = $(this).hasClass('active');
        if (!isActive) {
            $(this).next('.accordion-content').addClass('active').removeClass('inactive');
            $(this).addClass('active').removeClass('inactive');

        }
        else {
            $(this).next('.accordion-content').removeClass('active').addClass('inactive');
            $(this).removeClass('active').addClass('inactive');
        }
        return false;
    });
}

var accordionActivateItem = function (primaryAccordions) {

    var firstItem = $(".c-reminder-banner").find(".c-accordion__item")
    if ($('.c-accordion-open').parent().parent().length > 0) {
        firstItem = $('.c-accordion-open').parent().parent();
    }
    primaryAccordions.setActiveAccordion(firstItem.first(), false)
}


$(window).on('resize', function () {
    changeEvents();
});

$(window).on('orientationchange', function () {
    changeEvents();
});

function changeEvents() {
    deviceCheck();
    components();
    footerLinksToggle();
    radDropDownMaxWidth();
}

function components() {
    responsiveTables();
    colorboxModal();
}

function deviceCheck() {
    if ($(window).width() < tabletBreakpoint && $(window).width() >= phoneBreakpoint) {
        isMobile = true;
        isTablet = true;
        isPhone = false;
    }
    else if ($(window).width() < phoneBreakpoint) {
        isMobile = true;
        isPhone = true;
        isTablet = false;
    }
    else {
        isMobile = false;
        isTablet = false;
        isPhone = false;
    }
    if ("ontouchstart" in window || window.DocumentTouch && document instanceof DocumentTouch) {
        isTouch = true;
    }
    else {
        isTouch = false;
    }
}


function dashboardToggle() {

    var toggle = $(".sectiontoggle").find("#dashboard-section");
    var pathname = window.location.pathname;

    toggle.toggleClass("more");

    if (toggle.hasClass("more")) {
        if (pathname.startsWith("/en/")) {
            toggle.find(".update-text").text("Show more");
        } else {
            toggle.find(".update-text").text("Dangos mwy");
        }
    }
    else {
        if (pathname.startsWith("/en/")) {
            toggle.find(".update-text").text("Show less");
        } else {
            toggle.find(".update-text").text("Dangos llai");
        }
    }
}

function collapseState() {
    if (localStorage.getItem("menuCollapseState") == "Closed") {
        collapseMenu(true);
    }

}

function collapseMenu(onLoad = false) {

    if (onLoad) { $(".layout-dashboard").addClass("closed-no-transition") }
    else {
        if ($(".layout-dashboard").hasClass("closed-no-transition")) { $(".layout-dashboard").removeClass("closed-no-transition") }
        else { $(".layout-dashboard").toggleClass("closed"); }
    }

    //Check the current state
    if ($(".layout-dashboard").hasClass("closed")) { localStorage.setItem('menuCollapseState', 'Closed') }
    else { localStorage.setItem('menuCollapseState', 'Open') }

    $(".layout-secondary").find(".collapse-icon").toggleClass("fa-rotate-180")
}



function accessibility() {
    // accessibility functions
    keypressListener();

    function keypressListener() {
        // keypress config
        var skiptoContentKey = 's',
            sendToHomepageKey = '1',
            sendToContactKey = '8',
            sendToAccessibilityKey = '0';
        // leave unless functionality requires changing
        var isMac = navigator.userAgent.indexOf('Mac OS X') != -1;
        var windowLocation = window.location;
        // which language
        var language;
        if (windowLocation.href.indexOf("en") > -1) {
            language = 'en';
        }
        if (windowLocation.href.indexOf("cym") > -1) {
            language = 'cym';
        }

        // change access key if Mac
        var accessKey = 'alt';
        if (isMac) {
            accessKey = 'meta';
        }

        //skip to content
        $(document).bind('keydown', accessKey + '+' + skiptoContentKey, function (e) {
            //scroll to content position
            $('html,body').scrollTop($('#content').offset().top);
            return false;
        });
        //change page to homepage
        $(document).bind('keydown', accessKey + '+' + sendToHomepageKey, function (e) {
            window.location = '/';
            return false;
        });
        //change page to homepage
        $(document).bind('keydown', accessKey + '+' + sendToContactKey, function (e) {
            //change page to contact
            if (language == 'en') {
                window.location = '/contact/';
            }
            if (language == 'cym') {
                window.location = '/cysylltu/';
            }
            return false;
        });
        //change page to accessibility
        $(document).bind('keydown', accessKey + '+' + sendToAccessibilityKey, function (e) {
            //change page to contact
            if (language == 'en') {
                window.location = '/accessibility/';
            }
            if (language == 'cym') {
                window.location = '/hygyrchedd/';
            }
            return false;
        });
    }
}



var formUXInit = false;
var checkRadioButtonSelectedTimerInit = false;
function formUX() {
    if ($('fieldset').length > 0) {
        fieldsetLoop();
        if (!isTouch) {
            $('fieldset .RadButton_rt, fieldset label').on('click', function () {
                setTimeout(fieldsetLoop, 200);
            });
        }
        else {
            $('fieldset .RadButton_rt, fieldset label').on('focus', function () {
                setTimeout(fieldsetLoop, 200);
            });
        }
        $('fieldset input').on('blur', function () {
            var thisVal = $(this).val();
            if (thisVal != '') {
                $(this).parents('fieldset.other').removeClass('active').addClass('selected');
            }
            else {
                $(this).parent('fieldset.other').removeClass('selected').addClass('active');
            }
        });
        function fieldsetLoop() {
            var fieldsetVisibleLength = $('fieldset:visible').length;
            if (fieldsetVisibleLength > 0) {
                var i = 0;
                $('fieldset:visible').each(function () {
                    i++;
                    var thisID = $(this).attr('id');
                    // Other Fieldset
                    if ($(this).hasClass('other') && $(this).children('div').is(':hidden')) {
                        $(this).children('div').unwrap();
                    }
                    else if ($(this).hasClass('other')) {
                    }
                    var thisHasSelectedButton = $(this).find('.RadButton_rt').hasClass('rbLinkButtonChecked');
                    var thisSelected = $(this).hasClass('selected');
                    var thisActive = $(this).hasClass('active');
                    if (thisHasSelectedButton) {
                        $(this).addClass('selected').removeClass('active');
                        return;
                    }
                    else {
                        $(this).removeClass('selected')
                    }
                    if (!thisActive && !thisSelected) {
                        $(this).addClass('active');
                        return false;
                    }
                    return false;
                });
                var fieldsetVisibleNotSelectedLength = $("fieldset.selected:visible").length;
                if (fieldsetVisibleNotSelectedLength == fieldsetVisibleLength) {
                    $('.button-next').focus();
                    return false;
                }
            }
        }
    }
}

function boombox() {
    alert('BOOM');
}


function colorboxModal() {
    $(".cbox").colorbox({ iframe: true, scrolling: true, width: "40%", height: "80%" });
}

// imagefill to centralise images in containers regardless of the aspect ratio
//function imageFill() {
//    $('.article-item-image img').resizeToParent({ parent: '.article-item-image' });
//}

function footerLinksToggle() {
    $('.footer-list-toggle').unbind('click');
    if (isPhone) {
        $('.footer-list-toggle').bind('click', function (e) {
            $(this).parent('.footer-link-title').toggleClass('active').next('.footer-link-list').toggleClass('active');
        });
    }
}

function sectionToggle() {
    var inactiveClass = 'inactive';
    var activeClass = 'active';
    var currentUrl = $(location).attr('pathname');
    var currentUrlJoin = currentUrl.split('/').join('');

    // Add Section toggle Event
    $('.sectiontoggle-button').bind('click', function (e) {
        var thisHref = $(this).attr('href');
        var sectionToggled = $(thisHref);
        var sectionToggledId = thisHref.split('#')[1];
        // if is hidden
        if (sectionToggled.hasClass(inactiveClass)) {
            $(this).removeClass(activeClass);
            sectionToggled.removeClass(inactiveClass);
            // set cookie to true
            $.cookie(currentUrlJoin + '_' + sectionToggledId, true);
        }
        // if is visible
        else {
            $(this).addClass(activeClass);
            sectionToggled.addClass(inactiveClass);
            // set cookie to false
            $.cookie(currentUrlJoin + '_' + sectionToggledId, false);
        }
        return false;
    });
    var cookies = $.cookie();
    // Check cookies
    if (cookies) {
        for (var section in cookies) {
            if (cookies.hasOwnProperty(section)) {
                // stop if get to end of useful key values
                if (section == 'rtCookiePrivacySetting') break;
                var sectionSplitStart = section.split('_')[0];
                var sectionSplitEnd = section.split('_')[1];
                // add inactive classes if cookie true
                if (cookies[section] && sectionSplitStart == currentUrlJoin) {
                    $('#' + sectionSplitEnd).addClass(inactiveClass);
                    $('a[href="#' + sectionSplitEnd + '"').addClass(activeClass);
                }
                else if (!cookies[section] && sectionSplitStart == currentUrlJoin) {
                    $('a[href="#' + sectionSplitEnd + '"').removeClass(activeClass);
                }
            }
        }
    }
}

function customScrollbars() {
    $('.scrollable').scrollbar({
        "showArrows": true,
        "scrollx": "advanced",
        "scrolly": "advanced"
    });
}

// Rad dropdowns like to expand on mobile
var maxWidthIntervalSet = false;
function radDropDownMaxWidth() {
    var radDropDownExist = $('.RadDropDownList_rt').length > 0;
    if (!maxWidthIntervalSet && radDropDownExist && isMobile) {
        setInterval(function () {
            setMaxWidths();
        }, 500);
        maxWidthIntervalSet = true;
    }
    function setMaxWidths() {
        $('.RadDropDownList_rt').each(function () {
            var parentWidth = $(this).parent().width();
            $(this).css('maxWidth', parentWidth + 'px');
        });
    }
}

function sectionMenuTabs() {
    $('.clientside.section-menu a').each(function () {
        if ($(this).hasClass('menulinkactive')) {
            var dataTab = $(this).attr('data-tab');
            $('#' + dataTab).addClass('active');
        }
    });
    $('.clientside.section-menu a').on('click', function (e) {
        var dataTab = $(this).attr('data-tab');
        $(this).parents('.clientside.section-menu').find('a').removeClass('menulinkactive');
        $('#' + dataTab).siblings('.section-tab-content').removeClass('active');
        $(this).addClass('menulinkactive');
        $('#' + dataTab).addClass('active');
        e.preventDefault();
    });
}

function stopInputFocusZoom() {
    var inputFocus = false;
    var metaViewport = $('meta[name=viewport]');
    var metaViewportOriginalContent = metaViewport.attr('content');
    $('input[type=text], input[type=password], input[type=email]').focus(function () {
        if (!inputFocus) {
            metaViewport.attr('content', 'width=device-width, initial-scale=1.0, minimum-scale=1.0, user-scalable=0');
            inputFocus = true;
        }
    }).blur(function () {
        metaViewport.attr('content', metaViewportOriginalContent);
        inputFocus = false;
    });
}

function responsiveVideoEmbed() {
    if ($('iframe').length) {
        // make each embedded YouTube or Vimeo iframes responsive automatically
        $('iframe').each(function () {
            var thisSrc = $(this).attr('src')
            if (thisSrc != null && (thisSrc.indexOf('youtu') || thisSrc.indexOf('youtube') || thisSrc.indexOf('vimeo'))) {
                $(this).wrap('<div class="video-wrapper"></div>');
            }
        });
    }
}

function responsiveTables() {
    //initialise responsive tables
    if (isMobile) {
        $('.RadGrid table').footable({
            breakpoints: {
                phone: phoneBreakpoint,
                tablet: tabletBreakpoint
            }
        });
    }

    $('.footable').footable({
        showToggle: true,
        breakpoints: {
            phone: phoneBreakpoint,
            //tablet: tabletBreakpoint
            tablet: 600
        }
    });

    $('.footableEnforce').footable({
        showToggle: true,
        breakpoints: {
            phone: phoneBreakpoint,
            //tablet: tabletBreakpoint
            tablet: 800
        }
    });


    $('.footable-requests').footable({
        showToggle: true,
        breakpoints: {
            phone: 950,
            /*tablet: 1380*/
            tablet: 1180
        }
    });
}

function IEcheck() {
    if (/MSIE (\d+\.\d+);/.test(navigator.userAgent)) { //test for MSIE x.x;
        var ieversion = new Number(RegExp.$1) // capture x.x portion and store as a number
        //if (ieversion >= 8) {
        //}
        //else if (ieversion >= 7) {
        if (ieversion >= 7) {
            displayTableFixes();
            breadcrumbPseudoFix();
        }
        //else if (ieversion >= 6)
        //else if (ieversion >= 5)
    }
}

function displayTableFixes() {
    if (!isMobile) {
        // Fix menu
        $('.main-menu-items').wrapInner("<tr></tr>").wrap("<table></table>");
        $('.main-menu-items tr').unwrap();
        $('.main-menu li a').unwrap().wrap("<td></td>");
        // Fix layout
        $('.layout-split').wrapInner("<tr />").wrapInner("<table />");
        $('.layout-split > .layout-item').contents().unwrap('<div class=".layout-item">').wrap("<td class='layout-item' />");
    }
}

function breadcrumbPseudoFix() {
    // Fix missing pseudo elements
    var pseudoElement;
    if (!isMobile) {
        // right angle bracket
        pseudoElement = '&#12297;';
    }
    else {
        // left angle bracket
        pseudoElement = '&#12296;';
    }
    $('.breadcrumbs li').each(function () {
        $(this).prepend('<span class="pseudo">' + pseudoElement + '</span>');
    });
}

function tabbedContentBlock() {
    if ($(".tabbed").length > 0) {
        var tabContainer = ".tabbed";
        var titleContainer = '.tabbed-title';
        var contentContainer = '.tabbed-content';

        $(tabContainer).addClass("active");
        $(tabContainer).children(contentContainer).not(":first").hide();
        $(tabContainer).children(titleContainer).not(":first").addClass("right tabbed-title-secondary").detach().insertAfter($(tabContainer).children(titleContainer + ":first"));

        $(titleContainer).on('click', function () {
            var dataContentVisible = $(contentContainer + ":visible").attr('data-content');
            var dataContentRef = $(this).attr('data-content-ref');
            if (dataContentRef != dataContentVisible) {
                $(contentContainer).hide();
                $(tabContainer).children(contentContainer).each(function () {
                    if ($(this).attr('data-content') == dataContentRef) {
                        $(this).show();
                    }
                });
            }
        });
    }
}

function targetopener(mylink, closeme, closeonly) {
    if (!(window.focus && window.opener)) {
        return true;
    }
    window.opener.focus();
    if (!closeonly) {
        window.opener.location.href = mylink.href;
    }
    if (closeme) {
        window.close();
    }
    return false;
}

function carousel() {
    var total = $('.carousel img').length;
    //rand = Math.floor(Math.random() * total);

    var container = $(".carousel-container");

    container.find(".carousel").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        appendDots: container.find(' .slider-nav-container .slider-nav'),
        variableWidth: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 6000,
        //initialSlide: rand,
        rows: 0
    });
};

// Service Request and Enforcement Case Pages Scripts
function servicePageInit() {
    $("#content").addClass("service-request");

    $("#serviceRequestToggleLabels").change(togglelabels);
    if (readCookie("showServiceRequestLabels") == "yes") $("#serviceRequestToggleLabels").prop("checked", true);
    togglelabels(false);

    toggle_header_footer();

    $("#serviceRequestToggleHeaderFooter").change(toggleheaderfooter);
    if (readCookie("showServiceRequestHeaderFooter") == "yes") $("#serviceRequestToggleHeaderFooter").prop("checked", true);
    if (readCookie("showServiceRequestHeaderFooter") == "yes") toggle_header_footer();

    toggleLeftPanelSticky();
}

function createCookie(name, value, days) {
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        var expires = "; expires=" + date.toGMTString();
    }
    else var expires = "";

    document.cookie = name + "=" + value + expires + "; path=/";
}

function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

function eraseCookie(name) {
    createCookie(name, "", -1);
}

function togglelabels(setsrcookie = true) {
    const boxchecked = $("#serviceRequestToggleLabels").is(":checked")
    if (boxchecked) $(".service-request-search").removeClass("hide-labels");
    else $(".service-request-search").addClass("hide-labels");
    if (boxchecked) $(".hide-with-labels").css("display", "none");
    else $(".hide-with-labels").css("display", "");
    $(".service-request-results").css("min-height", "unset");
    $(".service-request-results").css("min-height", ($(".service-request-search").height() + 50) + "px")
    if (setsrcookie && boxchecked) createCookie("showServiceRequestLabels", "yes", 30);
    if (setsrcookie && !boxchecked) eraseCookie("showServiceRequestLabels");
}

function toggleheaderfooter(sethfcookie = true) {
    const boxchecked = $("#serviceRequestToggleHeaderFooter").is(":checked")
    toggle_header_footer();
    if (sethfcookie && boxchecked) createCookie("showServiceRequestHeaderFooter", "yes", 30);
    if (sethfcookie && !boxchecked) eraseCookie("showServiceRequestHeaderFooter");
    toggleLeftPanelSticky();
}

function toggleLeftPanelSticky() {
    if ($(".outer-wrapper-header").css("display") == "none") {
        $(".service-request-search").addClass("sticky");
        $(".left-panel").addClass("sticky");
        $(".right-panel").addClass("right-panel-padding");
    } else {
        $(".service-request-search").removeClass("sticky");
        $(".left-panel").removeClass("sticky");
        $(".right-panel").removeClass("right-panel-padding");
    }

}

function subjectOther(sender, args) {
    var selectedItem = sender.get_selectedItem();
    var selectedItemValue = selectedItem != null ? selectedItem.get_value() : sender.get_value();
    if (selectedItemValue == "OTHER") {
        $("#<%=cpcTitleOther.ClientID%>").show();
    }
    else {
        $("#<%=cpcTitleOther.ClientID%>").hide();
    }
}

function SaveSearch() {
    $.colorbox({
        inline: true,
        href: "#popup",
        width: 666,
        trapFocus: false,
    });
    $("div#colorbox").appendTo("form:first");
    $('#colorbox').css('z-index', '99');
    $('#cboxOverlay,#cboxWrapper').css('z-index', '98');
}

function IntelligentLinking(e = 'popup') {
    $.colorbox({
        inline: true,
        href: "#" + e,
        width: 1000,
        height: 800,
        trapFocus: false,
        scrolling: true,
        fixed: true,
    });
    $("div#colorbox").appendTo("form:first");
    $('#colorbox').css('z-index', '99');
    $('#cboxOverlay,#cboxWrapper').css('z-index', '98');
}

function cbSelectAll(e) {
    $(e).closest('table').find('td input:checkbox').prop('checked', e.checked);
}

function cbClickAll(e) {
    $(e).closest('table').find('td input:checkbox').click();
}

function conditionalPostback(sender, args) { if ((args.EventTarget.endsWith('btnClose'))) args.EnableAjax = true; else args.EnableAjax = false; }
jQuery(function () {
    $(".toggle-panel-toggle").click(function () {
        $(this).toggleClass("on").siblings(".toggle-panel-content").toggle(200);
    });
});

//function OnClientDropDownOpened(sender, eventArgs) {
//    //$(".rddlSlide").css("position", "fixed");
//    console.log("opened")
//}

//function OnClientDropDownClosed(sender, eventArgs) {
//    //$(".rddlSlide").css("position", "absolute");
//    console.log("closed")
//}

